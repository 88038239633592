import React from 'react';
import { Modal, Box, Typography, Button, Link, Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { PictureAsPdf } from '@mui/icons-material';

const PlanDetailsModalEbToolkit = ({ open, onClose, quote }) => {
    if (!quote) return null;

    const detailRows = [
        { label: 'Carrier', value: quote.issuerName },
        { label: 'Plan', value: quote.planMarketingName || quote.planName },
        { label: 'Metal Level', value: quote.metalLevel },
        { label: 'Plan Type', value: quote.planType },
        { label: 'Deductible (Individual)', value: quote.annualDeductibleIndividual },
        { label: 'Deductible (Family)', value: quote.annualDeductibleFamily },
        { label: 'Out-of-Pocket Max (Individual)', value: quote.annualOopMaxIndividual },
        { label: 'Out-of-Pocket Max (Family)', value: quote.annualOopMaxFamily },
        { label: 'Monthly Premium', value: `$${quote.rate.toFixed(2)}` },
        { label: 'Primary Care Visit', value: quote.primaryCareVisit },
        { label: 'Specialist Visit', value: quote.specialistVisit },
        { label: 'Urgent Care', value: quote.urgentCare },
        { label: 'Emergency Room Services', value: quote.emergencyRoomServices },
        { label: 'Generic Drugs', value: quote.genericDrugs },
        { label: 'Preferred Brand Drugs', value: quote.preferredBrandDrugs },
        { label: 'Non-Preferred Drugs', value: quote.nonPreferredDrugs },
        { label: 'Specialty Drugs', value: quote.specialtyDrugs },
        { label: 'HSA Eligible', value: quote.isHsaEligible },
    ];

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="plan-details-modal"
            aria-describedby="plan-details-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 600,
                bgcolor: 'background.paper',
                boxShadow: 24,
                borderRadius: 2,
                p: 4,
                maxHeight: '90vh',
                overflowY: 'auto'
            }}>
                <Typography id="plan-details-modal" variant="h6" component="h2" gutterBottom>
                    Plan Details
                </Typography>

                <TableContainer component={Paper} sx={{ mt: 2, mb: 2 }}>
                    <Table size="small">
                        <TableBody>
                            {detailRows.map((row) => (
                                <TableRow key={row.label}>
                                    <TableCell component="th" scope="row">
                                        <strong>{row.label}</strong>
                                    </TableCell>
                                    <TableCell align="right">{row.value || 'N/A'}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {quote.urlForBenefitSummaryCoverage && (
                    <Link
                        href={quote.urlForBenefitSummaryCoverage}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}
                    >
                        <PictureAsPdf sx={{ mr: 1 }} />
                        View Full Benefits Summary
                    </Link>
                )}

                <Button onClick={onClose} variant="contained" color="primary" sx={{ mt: 2 }}>
                    Close
                </Button>
            </Box>
        </Modal>
    );
};

export default PlanDetailsModalEbToolkit;