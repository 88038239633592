import React, { useEffect, useState } from "react";
import {
    Button,
    Container,
    TextField,
    Typography,
    Grid,
    Paper,
    Box,
    IconButton,
    Checkbox,
    FormControlLabel,
    Alert,
    Tooltip
} from "@mui/material/";
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import LoadingSpinner from "../LoadingSpinner";
import AddressTypeDropdown from "../AddressTypeDropdown";
import AddressAutoComplete from "../AddressAutoComplete";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import AuthTokenService from "../../services/AuthTokenService";
import {
    useGetAddressesQuery,
    useGetAddressTypesQuery,
    useUpdateAddressMutation
} from "../../reducers/enrollmentApiSlice";
import { useDispatch } from "react-redux";
import { completeAddressPage, updateAddressesData } from "../../reducers/benefitEnrollmentSlice";
import Branding from "../Branding";
import logoImage from '../../assets/logo/default_logo.png';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const AddAddressPage = ({ onNext, componentKey }) => {
    const { translate } = useCustomTranslation();
    const { user } = AuthTokenService.getAuthInfo();
    const userEmail = user;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { data: addressesData, isLoading: isAddressesLoading, refetch: refetchAddresses } = useGetAddressesQuery({ email: userEmail });
    const { data: addressTypes, isLoading: isAddressTypesLoading, error: addressTypesError } = useGetAddressTypesQuery();
    const [updateAddress, { isLoading: isUpdatingAddress }] = useUpdateAddressMutation();
    const dispatch = useDispatch();

    const [addresses, setAddresses] = useState([]);
    const [formErrors, setFormErrors] = useState([]);
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        if (addressesData && addressTypes) {
            const initialAddresses = addressesData.map(address => ({
                addressId: address.addressId,
                addressTypeId: address.addressTypeId ?? '',
                addressLine1: address.addressLine1 ?? '',
                addressLine2: address.addressLine2 ?? '',
                city: address.city ?? '',
                region: address.region ?? '',
                country: address.country ?? '',
                postalCode: address.postalCode ?? '',
                isActive: address.isActive,
                email: userEmail
            }));
            setAddresses(initialAddresses.length > 0 ? initialAddresses : [createEmptyAddress()]);
            setFormErrors(initialAddresses.map(() => ({})));
        }
    }, [addressesData, addressTypes, componentKey]);

    useEffect(() => {
        refetchAddresses();
    }, [componentKey]);

    const createEmptyAddress = () => ({
        addressId: 0,
        addressTypeId: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        region: '',
        country: '',
        postalCode: '',
        isActive: true,
        email: userEmail
    });

    const handleAddAddress = () => {
        setAddresses([...addresses, createEmptyAddress()]);
        setFormErrors([...formErrors, {}]);
    };

    const handleRemoveAddress = (index) => {
        const updatedAddresses = addresses.filter((_, i) => i !== index);
        const updatedFormErrors = formErrors.filter((_, i) => i !== index);
        setAddresses(updatedAddresses);
        setFormErrors(updatedFormErrors);
    };

    const handleAddressChange = (index, field, value) => {
        const updatedAddresses = addresses.map((address, i) =>
            i === index ? { ...address, [field]: value } : address
        );
        setAddresses(updatedAddresses);

        // Clear the error for the field that was just updated
        const updatedFormErrors = formErrors.map((errors, i) =>
            i === index ? { ...errors, [field]: '' } : errors
        );
        setFormErrors(updatedFormErrors);
    };

    const handleAddressAutoCompleteChange = (index, addressDetails) => {
        const { addressLine1, addressLine2, city, region, country, postalCode } = parseAddress(addressDetails);

        const updatedAddresses = addresses.map((address, i) =>
            i === index ? {
                ...address,
                addressLine1,
                addressLine2,
                city,
                region,
                country,
                postalCode
            } : address
        );
        setAddresses(updatedAddresses);

        // Clear errors for all fields updated by auto-complete
        const updatedFormErrors = formErrors.map((errors, i) =>
            i === index ? {
                ...errors,
                addressLine1: '',  // Ensure that errors for addressLine1 are cleared when valid
                city: '',
                region: '',
                country: '',
                postalCode: ''
            } : errors
        );
        setFormErrors(updatedFormErrors);
    };

    // Helper function to parse the address properly into its components
    const parseAddress = (addressDetails) => {
        const { addressLine1, addressLine2, city, region, country, postalCode } = addressDetails;
        const parsedAddressLine1 = addressLine1;
        const parsedCity = city;
        const parsedRegion = region;
        const parsedCountry = country;
        const parsedPostalCode = postalCode;

        return {
            addressLine1: parsedAddressLine1,
            addressLine2: addressLine2 || '',
            city: parsedCity,
            region: parsedRegion,
            country: parsedCountry,
            postalCode: parsedPostalCode
        };
    };

    const validateForm = () => {
        const newFormErrors = addresses.map(address => ({
            addressTypeId: !address.addressTypeId ? translate('Address type is required') : '',
            addressLine1: !address.addressLine1 || !address.addressLine1.match(/\d+/) ? translate('Full street address including house number is required') : '', // Ensure full street address
            city: !address.city ? translate('City is required') : '',
            region: !address.region ? translate('State is required') : '',
            country: !address.country ? translate('Country is required') : '',
            postalCode: !address.postalCode ? translate('Postal code is required') : ''
        }));

        setFormErrors(newFormErrors);

        return newFormErrors.every(errors => Object.values(errors).every(error => !error));
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        if (!validateForm()) {
            setMessages([translate('Please fill in all required fields for each address.')]);
            return;
        }

        try {
            for (const address of addresses) {
                await updateAddress(address).unwrap();
            }

            dispatch(updateAddressesData(addresses));
            dispatch(completeAddressPage());
            onNext();
        } catch (error) {
            console.error('Error updating addresses', error);
            setMessages([translate('An error occurred while updating addresses. Please try again.')]);
        }
    };

    if (isAddressesLoading || isAddressTypesLoading) {
        return <LoadingSpinner />;
    }

    if (addressTypesError) {
        return <Typography color="error">{translate('Failed to load address types')}</Typography>;
    }

    return (
        <Container maxWidth="md" sx={{ my: 4 }}>
            <Paper elevation={3} sx={{ p: 3 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4 }}>
                    <Branding logoUrl={logoImage} width={isMobile ? "150px" : "250px"} height={isMobile ? "45px" : "75px"} />
                    <Typography variant="h6" gutterBottom textAlign="center">
                        {translate('addressPage.instructions', { defaultValue: 'Please add your address information.' })}
                    </Typography>
                    {messages.length > 0 && (
                        <Alert severity="error" sx={{ width: '100%', mb: 2 }}>
                            {messages.map((message, index) => (
                                <Typography key={index}>{message}</Typography>
                            ))}
                        </Alert>
                    )}
                    <form onSubmit={handleFormSubmit}>
                        {addresses.map((address, index) => (
                            <Paper key={index} elevation={2} sx={{ p: 2, mb: 2, position: 'relative' }}>
                                <Typography variant="h6" gutterBottom>
                                    {translate('Address')} {index + 1}
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <AddressTypeDropdown
                                            value={address.addressTypeId || ''}
                                            onSelectionChange={(value) => handleAddressChange(index, 'addressTypeId', value)}
                                            error={!!formErrors[index]?.addressTypeId}
                                            helperText={formErrors[index]?.addressTypeId}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <AddressAutoComplete
                                            id={`address-${index}`}
                                            onChange={(addressDetails) => handleAddressAutoCompleteChange(index, addressDetails)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label={translate('Address Line 1 (Street)')}
                                            value={address.addressLine1 || ''}
                                            onChange={(e) => handleAddressChange(index, 'addressLine1', e.target.value)}
                                            fullWidth
                                            required
                                            error={!!formErrors[index]?.addressLine1}
                                            helperText={formErrors[index]?.addressLine1}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label={translate('Address Line 2')}
                                            value={address.addressLine2 || ''}
                                            onChange={(e) => handleAddressChange(index, 'addressLine2', e.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label={translate('City')}
                                            value={address.city || ''}
                                            onChange={(e) => handleAddressChange(index, 'city', e.target.value)}
                                            fullWidth
                                            required
                                            error={!!formErrors[index]?.city}
                                            helperText={formErrors[index]?.city}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label={translate('State')}
                                            value={address.region || ''}
                                            onChange={(e) => handleAddressChange(index, 'region', e.target.value)}
                                            fullWidth
                                            required
                                            error={!!formErrors[index]?.region}
                                            helperText={formErrors[index]?.region}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label={translate('Country')}
                                            value={address.country || ''}
                                            onChange={(e) => handleAddressChange(index, 'country', e.target.value)}
                                            fullWidth
                                            required
                                            error={!!formErrors[index]?.country}
                                            helperText={formErrors[index]?.country}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label={translate('Postal Code')}
                                            value={address.postalCode || ''}
                                            onChange={(e) => handleAddressChange(index, 'postalCode', e.target.value)}
                                            fullWidth
                                            required
                                            error={!!formErrors[index]?.postalCode}
                                            helperText={formErrors[index]?.postalCode}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={address.isActive}
                                                    onChange={(e) => handleAddressChange(index, 'isActive', e.target.checked)}
                                                />
                                            }
                                            label={translate('Active')}
                                        />
                                    </Grid>
                                </Grid>
                                {index > 0 && (
                                    <Tooltip title={translate('Remove Address')}>
                                        <IconButton
                                            aria-label={`remove-address-${index}`}
                                            onClick={() => handleRemoveAddress(index)}
                                            sx={{
                                                position: 'absolute',
                                                top: 8,
                                                right: 8,
                                                color: theme.palette.error.main,
                                            }}
                                        >
                                            <RemoveIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Paper>
                        ))}
                        <Grid container spacing={2} sx={{ mt: 2 }}>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    variant="outlined"
                                    startIcon={<AddIcon />}
                                    onClick={handleAddAddress}
                                    fullWidth
                                    aria-label={translate('Add Address')}
                                >
                                    {translate('Add Address')}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    disabled={isUpdatingAddress}
                                    aria-label={translate('Submit')}
                                >
                                    {isUpdatingAddress ? translate('Submitting...') : translate('Submit')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Paper>
        </Container>
    );
};

export default AddAddressPage;
