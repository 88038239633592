import React from 'react';
import { Box, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox, Typography } from '@mui/material';
import useCustomTranslation from "../../hooks/useCustomTranslation";

const DependentCoverage = ({ planId, dependentsData, dependentsCoverage, onDependentChange }) => {
    const { translate } = useCustomTranslation();

    const handleDependentChange = (dependent) => {
        onDependentChange(planId, dependent, !dependentsCoverage[dependent.dependentId]);
    };

    return (
        <Box sx={{ mt: 2 }}>
            <FormControl component="fieldset">
                <FormLabel component="legend">{translate('Select Dependents Covered')}</FormLabel>
                <Typography variant="body2" sx={{ mb: 2, color: 'blue' }}>
                    {translate('Please select the dependents covered by this plan')}
                </Typography>
                <FormGroup>
                    {dependentsData.map(dependent => (
                        <FormControlLabel
                            key={dependent.dependentId}
                            control={
                                <Checkbox
                                    checked={!!dependentsCoverage[dependent.dependentId]}
                                    onChange={() => handleDependentChange(dependent)}
                                    name={dependent.person.firstName}
                                />
                            }
                            label={`${dependent.person.firstName} ${dependent.person.lastName}`}
                        />
                    ))}
                </FormGroup>
            </FormControl>
        </Box>
    );
};

export default DependentCoverage;