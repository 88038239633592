import React, {useState, useCallback, useMemo} from 'react';
import {
    Typography, Table, TableHead, TableBody, TableRow, TableCell,
    Paper, Container, Box
} from '@mui/material';
import QuoteFiltersHSA from './QuoteFiltersHSA';
import QuoteRowHSA from './QuoteRowHSA';
import PaymentInfoDialog from './HSAModals/PaymentInfoDialog';
import useCustomTranslation from "../../../hooks/useCustomTranslation";
import ICHRAPlanDetailsModalHSA from "./HSAModals/ICHRAPlanDetailsModalHSA";
import PropTypes from "prop-types";
import useFetchEnrollmentObjectInformation from "../../../hooks/useFetchEnrollmentObjectInformation";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedPlan} from "../../../reducers/hsaReducer";

const QuoteListHSA = ({ quotes }) => {
    const { translate } = useCustomTranslation();

    const dispatch = useDispatch();
    const selectedDependents = useSelector((state) => state.hsa.selectedDependents);
    const selectedPlan = useSelector((state) => state.hsa.selectedPlanInfo);

    const [filters, setFilters] = useState({
        selectedCarrier: '',
        rateRange: [0, Math.max(...quotes.map(quote => quote.censusTotal))],
    });

    const {enrollmentObject, isLoading: enrollmentObjectIsLoading, isError: enrollmentObjectIsError} = useFetchEnrollmentObjectInformation({selectedQuote: selectedPlan, selectedDependents: selectedDependents});

    // Modal states:
    const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
    const [planDetailsDialogOpen, setPlanDetailsDialogOpen] = useState(false);

    const handleSelectQuote = (quote) => {
        dispatch(setSelectedPlan(quote));
        setPlanDetailsDialogOpen(false);
        setPaymentDialogOpen(true);
    };

    const handlePlanDetails = (quote) => {
        dispatch(setSelectedPlan(quote));
        setPaymentDialogOpen(false);
        setPlanDetailsDialogOpen(true);
    };

    const handlePlanDetailsDialogClose = () => {
        setPlanDetailsDialogOpen(false);
        dispatch(setSelectedPlan(null));
    }

    const handlePaymentDialogClose = () => {
        setPaymentDialogOpen(false);
        dispatch(setSelectedPlan(null));
    }

    const handleFilterChange = useCallback((newFilters) => {
        setFilters(newFilters);
    }, []);

    const handlePaymentSubmission = (paymentInfo) => {
        console.log('Enrollment submitted with payment info:', paymentInfo, 'and selected quote:', selectedPlan);
        setPaymentDialogOpen(false);
    };

    const filteredQuotes = useMemo(() => {
        return quotes?.filter(quote => {
            // [filters.rateRange[0], filters.rateRange[1]]
            const priceWithinMonthlyCostRange = quote.censusTotal >= filters.rateRange[0] && quote.censusTotal <= filters.rateRange[1];
            const isAllCarriersSelected = filters.selectedCarrier === '';
            const isSameCarrierName = quote.carrierName === filters.selectedCarrier || isAllCarriersSelected;
            return priceWithinMonthlyCostRange && isSameCarrierName;
        }) ?? [];
        }, [filters, quotes]
    );

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper elevation={3} sx={{ borderRadius: 2 }}>
                <Box sx={{ p: 2 }}>
                    <QuoteFiltersHSA quotes={quotes} filters={filters} onFilterChange={handleFilterChange} />
                </Box>

                <Table aria-label={translate('quote_list_table_label')} sx={{ tableLayout: 'fixed' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{translate('Carrier')}</TableCell>
                            <TableCell>{translate('Plan')}</TableCell>
                            <TableCell>{translate('Premium')}</TableCell>
                            <TableCell>{translate('Actions')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredQuotes.map((quote, index) => (
                            <QuoteRowHSA
                                key={index}
                                quote={quote}
                                isSelected={selectedPlan === quote}
                                onSelect={() => handleSelectQuote(quote)}
                                onPlanDetailsClick={() => handlePlanDetails(quote)}
                            />
                        ))}
                    </TableBody>
                </Table>
            </Paper>

            {selectedPlan && (
                <>
                    {/* NOTE: This pops up when you click select plan and closes when you submit your payment or click cancel or click off the modal. */}
                    <PaymentInfoDialog
                        open={paymentDialogOpen}
                        onClose={() => handlePaymentDialogClose()}
                        selectedQuote={selectedPlan}
                        enrollmentObject={enrollmentObject}
                        enrollmentObjectIsError={enrollmentObjectIsError}
                        enrollmentObjectIsLoading={enrollmentObjectIsLoading}
                        onSubmit={handlePaymentSubmission}
                    />

                    <ICHRAPlanDetailsModalHSA open={planDetailsDialogOpen} onClose={() => {handlePlanDetailsDialogClose()}} quote={selectedPlan} onSelectQuote={handleSelectQuote} />
                </>
            )}
        </Container>
    );
};

QuoteListHSA.propTypes = {
    quotes: PropTypes.array.isRequired,
};


export default QuoteListHSA;
