import React from 'react';
import { TableRow, TableCell, Button, Typography, Box, Tooltip } from '@mui/material';
import Branding from '../../Branding';
import logoImage from '../../../assets/images/AltLogo.png';

const QuoteRowHSA = ({ quote, isSelected, onSelect, onPlanDetailsClick, isMobile, isTablet }) => {
    const getPlanColor = (planType) => {
        const type = planType.toLowerCase();
        if (type.includes('bronze')) return '#cd7f32';
        if (type.includes('silver')) return '#c0c0c0';
        if (type.includes('gold')) return '#ffd700';
        if (type.includes('platinum')) return '#e5e4e2'; // Platinum color
        return 'transparent';
    };

    const planColor = getPlanColor(quote.webLevel);

    const shortenPlanName = (name, maxLength = 20) => {
        if (name.length <= maxLength) return name;
        return `${name.substring(0, maxLength)}...`;
    };

    const renderCarrier = () => (
        <Box display="flex" alignItems="center">
            <Branding
                logoUrl={quote.carrierLogo || logoImage}
                width="50px"
                height="50px"
                style={{ marginRight: '10px' }}
            />
            <Typography variant="subtitle1">{quote.carrierName}</Typography>
        </Box>
    );

    const renderPlan = () => (
        <Box>
            <Tooltip title={quote.planDescpt}>
                <Typography variant="body2">{shortenPlanName(quote.planDescpt)}</Typography>
            </Tooltip>
            <Box
                sx={{
                    backgroundColor: planColor,
                    color: planColor === '#ffd700' ? 'black' : 'white',
                    padding: '2px 8px',
                    borderRadius: '4px',
                    display: 'inline-block',
                    mt: 1
                }}
            >
                {quote.webLevel}
            </Box>
        </Box>
    );

    const renderMobileView = () => (
        <>
            <TableCell>{renderCarrier()}</TableCell>
            <TableCell>
                {renderPlan()}
                <Box mt={2}>
                    <Typography variant="body2">Annual Deductible: {quote.webAnnDed}</Typography>
                    <Typography variant="body2">Out of Pocket Max: {quote.webOopMax}</Typography>
                    <Typography variant="h6" mt={1}>Monthly Premium: ${quote.censusTotal.toFixed(2)}</Typography>
                    <Button
                        variant="contained"
                        color={isSelected ? 'primary' : 'success'}
                        onClick={() => onSelect(quote)}
                        fullWidth
                        sx={{ mt: 1 }}
                    >
                        {isSelected ? 'Selected' : 'Select Plan'}
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => onPlanDetailsClick(quote)}
                        fullWidth
                        sx={{ mt: 1 }}
                    >
                        Plan details
                    </Button>
                </Box>
            </TableCell>
        </>
    );

    const renderTabletView = () => (
        <>
            <TableCell>{renderCarrier()}</TableCell>
            <TableCell>{renderPlan()}</TableCell>
            <TableCell align="center">{quote.webAnnDed}</TableCell>
            <TableCell align="center">
                <Typography variant="h6">${quote.censusTotal.toFixed(2)}</Typography>
                <Button
                    variant="contained"
                    color={isSelected ? 'primary' : 'success'}
                    onClick={() => onSelect(quote)}
                    fullWidth
                    sx={{ mt: 1 }}
                >
                    {isSelected ? 'Selected' : 'Select Plan'}
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => onPlanDetailsClick(quote)}
                    fullWidth
                    sx={{ mt: 1 }}
                >
                    Plan details
                </Button>
            </TableCell>
        </>
    );

    const renderDesktopView = () => (
        <>
            <TableCell>{renderCarrier()}</TableCell>
            <TableCell>{renderPlan()}</TableCell>
            <TableCell align="center">{quote.webAnnDed}</TableCell>
            <TableCell align="center">{quote.webOopMax}</TableCell>
            <TableCell align="center">
                <Typography variant="h6">${quote.censusTotal.toFixed(2)}</Typography>
            </TableCell>
            <TableCell align="center">
                <Button
                    variant="contained"
                    color={isSelected ? 'primary' : 'success'}
                    onClick={() => onSelect(quote)}
                    fullWidth
                >
                    {isSelected ? 'Selected' : 'Select Plan'}
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => onPlanDetailsClick(quote)}
                    fullWidth
                    sx={{ mt: 1 }}
                >
                    Plan details
                </Button>
            </TableCell>
        </>
    );

    return (
        <TableRow sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
            {isMobile ? renderMobileView() :
                isTablet ? renderTabletView() :
                    renderDesktopView()}
        </TableRow>
    );
};

export default QuoteRowHSA;