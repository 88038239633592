import React, { useState, useEffect } from 'react';
import {Container, Paper, Typography, Box, Alert, useTheme} from '@mui/material';
import {
    useGetPersonalAndEmploymentDetailsQuery,
    useGetLocationsQuery,
    useGetEmployerCalculatedEffectiveDateQuery
} from '../../../reducers/enrollmentApiSlice';
import authTokenService from '../../../services/AuthTokenService';
import useCustomTranslation from '../../../hooks/useCustomTranslation';
import EbZipCodeInput from './EbZipCodeInput';
import HealthPlanQuoteViewer from './HealthPlanQuoteViewer';
import dayjs from 'dayjs';
import useGetEmployerCode from "../../../hooks/useGetEmployerCode";
import LoadingSpinner from "../../LoadingSpinner";

const EbIchraEnrollment = () => {
    const theme = useTheme();
    const { translate } = useCustomTranslation();
    const [zipCode, setZipCode] = useState('');
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [dateOfBirth, setDateOfBirth] = useState('');

    const authInfo = authTokenService.getAuthInfo();
    const userEmail = authInfo.user;

    const { data: personalDetails, isLoading: isPersonalDetailsLoading, isError: isPersonalDetailsError } = useGetPersonalAndEmploymentDetailsQuery({ email: userEmail });
    const { data: locationData, isLoading: isLocationLoading, isError: isLocationError } = useGetLocationsQuery(zipCode, { skip: !zipCode });

    const { employerCode, isEmployerCodeLoading, isError: isEmployerCodeError } = useGetEmployerCode({ userEmail: userEmail });
    const { data: calculatedEffectiveDate, isError: calculatedEffectiveDateError, isLoading: calculatedEffectiveDateIsLoading, calculatedEffectiveDateIsSuccess }
        = useGetEmployerCalculatedEffectiveDateQuery({employerCode, email: userEmail}, {
        skip: !employerCode || !userEmail // Don't execute the query until the employerCode and the user email is available.
    });

    const effectiveDate = calculatedEffectiveDate?.data?.calculatedHsaEffectiveDate ?? null;

    useEffect(() => {
        console.log('Component mounted and initial state setup.');
    }, []);

    useEffect(() => {
        if (personalDetails) {
            console.log('Personal Details Loaded:', personalDetails);
            setZipCode(personalDetails.zipCode || '');
            setDateOfBirth(dayjs(personalDetails.dateOfBirth).format('YYYY-MM-DD'));
        }
    }, [personalDetails]);

    const handleZipCodeChange = (newZipCode) => {
        console.log('Zip Code changed:', newZipCode);
        setZipCode(newZipCode);
        setSelectedLocation(null); // Reset selected location when zip code changes
    };

    const handleLocationChange = (location) => {
        console.log('Location received from EbZipCodeInput:', location);
        setSelectedLocation(location);
    };

    const isLoading = isPersonalDetailsLoading || isLocationLoading || isEmployerCodeLoading || calculatedEffectiveDateIsLoading;
    const hasError = isPersonalDetailsError || isLocationLoading || isEmployerCodeError || calculatedEffectiveDateError;

    if (effectiveDate && zipCode && selectedLocation && dateOfBirth) {
        console.log("All conditions met, rendering HealthPlanQuoteViewer with the following values:");
        console.log("selectedEffectiveDate:", effectiveDate);
        console.log("zipCode:", zipCode);
        console.log("selectedLocation:", selectedLocation);
        console.log("dateOfBirth:", dateOfBirth);
    } else {
        console.log("Not all fields are set yet.");
        console.log("selectedEffectiveDate:", effectiveDate);
        console.log("zipCode:", zipCode);
        console.log("selectedLocation:", selectedLocation);
        console.log("dateOfBirth:", dateOfBirth);
    }

    return (
        <Container maxWidth="lg">
            <Paper elevation={3} sx={{ p: 3, mt: 4, mb: 4, minHeight: '900px'  }}>
                <Typography variant="h4" gutterBottom>
                    {translate('Eb ICHRA Enrollment')}
                </Typography>

                {
                    isLoading && (
                        <LoadingSpinner />
                    )
                }

                {
                    !isLoading && hasError && (
                        <Alert
                            severity="error"
                            sx={{
                                mb: theme.spacing(2),
                                color: theme.palette.error.main,
                                backgroundColor: theme.palette.error.light,
                            }}
                        >
                            {translate("Failed to load the EB ICHRA Enrollment Info. Please try again later.")}
                        </Alert>
                    )
                }

                {
                    !isLoading && !hasError && (
                        <>
                            <Box sx={{ mb: 4 }}>
                                <EbZipCodeInput
                                    onZipCodeChange={handleZipCodeChange}
                                    zipCode={zipCode}
                                    onLocationChange={handleLocationChange}  // Pass the new location change handler
                                />
                            </Box>

                            {effectiveDate && zipCode && selectedLocation && dateOfBirth ? (
                                <HealthPlanQuoteViewer
                                    zipCode={zipCode}
                                    dateOfBirth={dateOfBirth}
                                    countyName={selectedLocation.countyName}
                                    effectiveDate={effectiveDate}
                                />
                            ) : (
                                <Typography>Waiting for required fields to be set before rendering the HealthPlanQuoteViewer...</Typography>
                            )}
                        </>
                    )
                }
            </Paper>
        </Container>
    );
};

export default EbIchraEnrollment;
