import React, { useState, useCallback } from 'react';
import {
    Typography, Table, TableHead, TableBody, TableRow, TableCell,
    Paper, Container, Box, TableContainer
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedPlan, clearSelectedPlan } from '../../../reducers/ebToolkitReducer';
import { useGetHealthPlanQuoteQuery } from '../../../reducers/enrollmentApiSlice';
import QuoteFilterEbToolkit from './QuoteFilterEbToolkit';
import QuoteRowEbToolkit from './QuoteRowEbToolkit';
import PlanDetailsModalEbToolkit from './Modals/PlanDetailsModalEbToolkit';
import useCustomTranslation from "../../../hooks/useCustomTranslation";
import PropTypes from "prop-types";
import LoadingSpinner from "../../LoadingSpinner";

const HealthPlanQuoteViewer = ({ zipCode, dateOfBirth, countyName, effectiveDate }) => {
    const { translate } = useCustomTranslation();
    const dispatch = useDispatch();

    const selectedPlanInfo = useSelector((state) => state.ebToolkit.selectedPlanInfo);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filters, setFilters] = useState({
        selectedCarrier: '',
        rateRange: [0, 1000],
    });

    const { data: healthPlanQuoteData, isLoading, error } = useGetHealthPlanQuoteQuery({
        zipcode: zipCode,
        dateofbirth: dateOfBirth,
        effectivedate: effectiveDate,
        countyname: countyName,
    });

    const handleFilterChange = useCallback((newFilters) => {
        setFilters(newFilters);
    }, []);

    const handleSelectQuote = (quote) => {
        dispatch(setSelectedPlan(quote));  // Set the selected plan in Redux
    };

    const handleOpenDetails = (quote) => {
        dispatch(setSelectedPlan(quote));
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        dispatch(clearSelectedPlan());  // Clear the selected plan from Redux
        setIsModalOpen(false);
    };

    const healthPlans = healthPlanQuoteData?.healthPlans || [];

    const filteredQuotes = healthPlans.filter((plan) => {
        return (
            (filters.selectedCarrier === '' || plan.issuerName === filters.selectedCarrier) &&
            plan.rate >= filters.rateRange[0] &&
            plan.rate <= filters.rateRange[1]
        );
    });

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (error) {
        return (
            <Typography color="error">
                {translate('Error fetching health plan quote:')} {error.message}
            </Typography>
        );
    }

    if (!Array.isArray(healthPlans) || healthPlans.length === 0) {
        return (
            <Typography color="error">
                {translate('No health plan data available.')}
            </Typography>
        );
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper elevation={3} sx={{ borderRadius: 2, overflow: 'hidden' }}>
                <Box sx={{ p: 2, borderBottom: '1px solid #e0e0e0' }}>
                    <QuoteFilterEbToolkit quotes={healthPlans} filters={filters} onFilterChange={handleFilterChange} />
                </Box>
                <TableContainer component={Paper}>
                    <Table aria-label={translate('quote_list_table_label')} sx={{ tableLayout: 'fixed', minWidth: 800 }}>
                        <TableHead>
                            <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                                <TableCell align="left" sx={{ fontWeight: 'bold', width: '15%' }}>
                                    <Typography variant="subtitle1">{translate('Carrier')}</Typography>
                                </TableCell>
                                <TableCell align="left" sx={{ fontWeight: 'bold', width: '25%' }}>
                                    <Typography variant="subtitle1">{translate('Plan')}</Typography>
                                </TableCell>
                                <TableCell align="center" sx={{ fontWeight: 'bold', width: '15%' }}>
                                    <Typography variant="subtitle1">{translate('Deductible')}</Typography>
                                </TableCell>
                                <TableCell align="center" sx={{ fontWeight: 'bold', width: '15%' }}>
                                    <Typography variant="subtitle1">{translate('OOP Max')}</Typography>
                                </TableCell>
                                <TableCell align="center" sx={{ fontWeight: 'bold', width: '15%' }}>
                                    <Typography variant="subtitle1">{translate('Premium')}</Typography>
                                </TableCell>
                                <TableCell align="center" sx={{ fontWeight: 'bold', width: '15%' }}>
                                    <Typography variant="subtitle1">{translate('Actions')}</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredQuotes.map((quote) => (
                                <QuoteRowEbToolkit
                                    key={quote.id}
                                    quote={quote}
                                    isSelected={selectedPlanInfo === quote}
                                    onSelect={() => handleSelectQuote(quote)}
                                    onOpenDetails={handleOpenDetails}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            {/* Plan Details Modal */}
            {selectedPlanInfo && (
                <PlanDetailsModalEbToolkit
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    quote={selectedPlanInfo}
                />
            )}
        </Container>
    );
};

HealthPlanQuoteViewer.propTypes = {
    zipCode: PropTypes.string.isRequired,
    dateOfBirth: PropTypes.string.isRequired,
    countyName: PropTypes.string.isRequired,
    effectiveDate: PropTypes.string.isRequired,
};

export default HealthPlanQuoteViewer;
