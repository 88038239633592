import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Button, Container, Paper, TextField, Alert, Snackbar } from '@mui/material';
import { useRequestNewQuoteMutation, useGetPersonalAndEmploymentDetailsQuery, useGetDependentsByAccountEmailQuery, useGetAddressesQuery } from '../../../reducers/enrollmentApiSlice';
import useCustomTranslation from '../../../hooks/useCustomTranslation';
import DependentCoverage from '../../ReviewBenefitPage/DependentCoverage';
import authTokenService from '../../../services/AuthTokenService';
import dayjs from 'dayjs';

const RequestNewQuoteFormHSA = ({ selectedEffectiveDate, selectedIchraParentGroupId, onSuccess }) => {
    const { translate } = useCustomTranslation();

    // Get the logged-in user's email and authentication token info
    const authInfo = authTokenService.getAuthInfo();
    const userEmail = authInfo.user;

    // State to manage form data
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        dob: '',
        zip: '',
        email: userEmail || '',
        effectiveDate: selectedEffectiveDate,
        ichraParentGroupId: selectedIchraParentGroupId,
        dependentList: [],
        useR_ID:'',  // Assuming this is the user ID stored in the auth token
        password: '' // Assuming password might be stored in authTokenService or elsewhere
    });

    const [dependentsCoverage, setDependentsCoverage] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertSeverity, setAlertSeverity] = useState('success');
    const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);

    // Fetch personal details from the API
    const { data: personalDetails, isLoading: isPersonalDetailsLoading, error: personalDetailsError } = useGetPersonalAndEmploymentDetailsQuery({ email: userEmail });

    // Fetch dependents from the API
    const { data: dependentsData, isLoading: isDependentsLoading, error: dependentsError } = useGetDependentsByAccountEmailQuery({ email: userEmail });

    // Fetch addresses from the API
    const { data: addressesData, isLoading: isAddressesLoading, error: addressesError } = useGetAddressesQuery({ email: userEmail });

    const [requestNewQuote, { isLoading: isQuoteRequestLoading }] = useRequestNewQuoteMutation();

    // Helper function to format ZIP code to first 5 digits
    const formatZip = (zipCode) => {
        return zipCode ? zipCode.slice(0, 5) : "";
    };

    // Auto-fill form data when API data is available
    useEffect(() => {
        if (personalDetails) {
            // Parse and format the dateOfBirth into 'YYYY-MM-DD' format for the date input
            const formattedDob = dayjs(personalDetails.dateOfBirth, ['M/D/YYYY', 'MM/DD/YYYY']).format('YYYY-MM-DD');
            setFormData(prevData => ({
                ...prevData,
                firstName: personalDetails.firstName || '',
                lastName: personalDetails.lastName || '',
                dob: formattedDob || '',  // Ensure date is formatted correctly
                email: personalDetails.email || userEmail || '',
                gender: personalDetails.genderId === 1 ? 'M' : 'F',
            }));
        }
    }, [personalDetails, userEmail]);

    // Auto-fill ZIP code when address data is available
    useEffect(() => {
        if (addressesData && addressesData.length > 0) {
            const primaryAddress = addressesData[0];  // Assuming the first address is the primary address
            const formattedZip = formatZip(primaryAddress.postalCode);  // Format ZIP code
            setFormData(prevData => ({
                ...prevData,
                zip: formattedZip || ''
            }));
        }
    }, [addressesData]);

    // Initialize dependents coverage state
    useEffect(() => {
        if (dependentsData) {
            const initialCoverage = dependentsData.reduce((coverage, dependent) => {
                coverage[dependent.dependentId] = false;
                return coverage;
            }, {});
            setDependentsCoverage(initialCoverage);
        }
    }, [dependentsData]);

    // Validate the form
    useEffect(() => {
        const isValid = formData.firstName && formData.lastName && formData.dob && formData.zip && formData.email;
        setIsFormValid(isValid);
    }, [formData]);

    // Handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle dependent coverage change
    const handleDependentChange = (planId, dependent, isSelected) => {
        setDependentsCoverage((prevCoverage) => ({
            ...prevCoverage,
            [dependent.dependentId]: isSelected,
        }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!isFormValid) {
            setAlertMessage(translate('Please fill in all required fields.'));
            setAlertSeverity('error');
            return;
        }

        try {
            // Filter selected dependents and map required fields
            const selectedDependents = dependentsData?.filter(dependent => dependentsCoverage[dependent.dependentId]) || [];
            const formattedDependents = selectedDependents.map(dependent => ({
                dob: dayjs(dependent.person.dateOfBirth).format('YYYY-MM-DD'),
                dependentType: dependent.relationshipType || ''
            }));

            const requestData = {
                useR_ID: formData.useR_ID,  // Add user ID
                password: formData.password,  // Add password
                firstName: formData.firstName,
                lastName: formData.lastName,
                dob: formData.dob,
                zip: formData.zip,
                email: formData.email,
                effectiveDate: formData.effectiveDate,
                ichraParentGroupId: formData.ichraParentGroupId,
                dependentList: formattedDependents,
            };

            // Call the API
            const response = await requestNewQuote(requestData).unwrap();
            setShowSuccessSnackbar(true);

            // Call onSuccess handler with quote data and selected dependents
            const selectedDependentsIds = selectedDependents.map(dep => dep.dependentId.toString());
            onSuccess(response, selectedDependentsIds);

            setAlertMessage(translate('Quote requested successfully!'));
            setAlertSeverity('success');
        } catch (error) {
            console.error('Request New Quote Error:', error);
            setAlertMessage(translate('An error occurred while requesting a quote.'));
            setAlertSeverity('error');
        }
    };

    if (isPersonalDetailsLoading || isDependentsLoading || isAddressesLoading) {
        return <Typography>{translate('Loading...')}</Typography>;
    }

    if (personalDetailsError || dependentsError || addressesError) {
        return <Typography>{translate('Error fetching data.')}</Typography>;
    }

    return (
        <Container maxWidth="md" sx={{ my: 4 }}>
            <Paper elevation={3} sx={{ p: 3 }}>
                <Typography variant="h5" gutterBottom>
                    {translate('Request New Quote')}
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={translate('First Name')}
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={translate('Last Name')}
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={translate('Date of Birth')}
                                name="dob"
                                type="date"
                                value={formData.dob}
                                onChange={handleInputChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={translate('ZIP Code')}
                                name="zip"
                                value={formData.zip}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={translate('Email')}
                                name="email"
                                type="email"
                                value={formData.email}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>

                    <Typography variant="h6" sx={{ mt: 4 }}>
                        {translate('Select Dependents')}
                    </Typography>

                    {/* Dependent Coverage Component */}
                    <DependentCoverage
                        planId={selectedIchraParentGroupId}
                        dependentsData={dependentsData || []}
                        dependentsCoverage={dependentsCoverage}
                        onDependentChange={handleDependentChange}
                    />

                    {alertMessage && (
                        <Alert severity={alertSeverity} sx={{ mt: 2 }}>
                            {alertMessage}
                        </Alert>
                    )}

                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                        <Button type="submit" variant="contained" color="primary" disabled={isQuoteRequestLoading}>
                            {translate('Submit Request')}
                        </Button>
                    </Box>
                </form>
            </Paper>

            <Snackbar
                open={showSuccessSnackbar}
                autoHideDuration={6000}
                onClose={() => setShowSuccessSnackbar(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={() => setShowSuccessSnackbar(false)} severity="success" sx={{ width: '100%' }}>
                    {translate('Quote requested successfully!')}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default RequestNewQuoteFormHSA;
