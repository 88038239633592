import React from 'react';
import { Card, CardContent, CardActions, Typography, Button, Grid, Chip, Tooltip, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const QuoteCardHSA = ({ quote, isSelected, onSelect }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
    };

    return (
        <Card
            variant="outlined"
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'box-shadow 0.3s',
                '&:hover': {
                    boxShadow: 3,
                },
            }}
        >
            <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h6" gutterBottom component="h2">
                    {quote.planDescpt}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body2"><strong>Carrier:</strong> {quote.carrierName} ({quote.carrierSymbol})</Typography>
                        <Typography variant="body2"><strong>Plan Type:</strong> {quote.typePlan}</Typography>
                        <Typography variant="body2"><strong>Network:</strong> {quote.networkName}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body2"><strong>Deductible:</strong> {formatCurrency(quote.webAnnDed)}</Typography>
                        <Typography variant="body2"><strong>Out-of-Pocket Max:</strong> {formatCurrency(quote.webOopMax)}</Typography>
                        <Chip
                            icon={<AccessibilityNewIcon />}
                            label={quote.hsaYN === 'Y' ? 'HSA Eligible' : 'Not HSA Eligible'}
                            color={quote.hsaYN === 'Y' ? 'success' : 'default'}
                            size="small"
                            sx={{ mt: 1 }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={6} sm={3}>
                        <Tooltip title="Doctor Visit Cost">
                            <Chip icon={<LocalHospitalIcon />} label={quote.webDrVisit} size="small" />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Tooltip title="Prescription Cost">
                            <Chip icon={<LocalPharmacyIcon />} label={quote.webRx} size="small" />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Tooltip title="Emergency Room Cost">
                            <Chip icon={<LocalHospitalIcon />} label={quote.webEr} size="small" />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Tooltip title="Hospital Cost">
                            <Chip icon={<LocalHospitalIcon />} label={quote.webHospital} size="small" />
                        </Tooltip>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions sx={{ justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
                <Typography variant="h6" color="primary">
                    <AttachMoneyIcon /> {formatCurrency(quote.censusTotal)}/month
                </Typography>
                <Button
                    variant={isSelected ? "contained" : "outlined"}
                    color={isSelected ? 'primary' : 'inherit'}
                    onClick={() => onSelect(quote)} // Trigger the selection and payment dialog
                    aria-label={isSelected ? "Selected plan" : "Select this plan"}
                >
                    {isSelected ? 'Selected' : 'Select Plan'}
                </Button>
            </CardActions>
            <CardActions>
                <Link
                    href={quote.pdfLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="hover"
                    sx={{ width: '100%', textAlign: 'center' }}
                >
                    View Plan Details (PDF)
                </Link>
            </CardActions>
        </Card>
    );
};

export default QuoteCardHSA;
