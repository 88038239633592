// src/reducers/hsaReducer.js

// Action Types
const SET_SELECTED_PLAN = 'hsa/SET_SELECTED_PLAN';
const CLEAR_SELECTED_PLAN = 'hsa/CLEAR_SELECTED_PLAN';
const SET_SELECTED_DEPENDENTS = 'hsa/SET_SELECTED_DEPENDENTS';

// Initial State
const initialState = {
    selectedPlanInfo: null,
    selectedDependents: [],
};

// Reducer
const hsaReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_PLAN:
            return {
                ...state,
                selectedPlanInfo: action.payload,
            };
        case CLEAR_SELECTED_PLAN:
            return {
                ...state,
                selectedPlanInfo: null,
            };
        case SET_SELECTED_DEPENDENTS:
            return {
                ...state,
                selectedDependents: action.payload,
            };
        default:
            return state;
    }
};

// Action Creators
export const setSelectedPlan = (plan) => ({
    type: SET_SELECTED_PLAN,
    payload: plan,
});

export const setSelectedDependents = (dependents) => ({
    type: SET_SELECTED_DEPENDENTS,
    payload: dependents,
});

export const clearSelectedPlan = () => ({
    type: CLEAR_SELECTED_PLAN,
});

export default hsaReducer;
