import React, {useState, useCallback} from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Alert } from '@mui/material';
import useCustomTranslation from '../../../../hooks/useCustomTranslation';
import { useSubmitApplicationMutation } from '../../../../reducers/enrollmentApiSlice';
import PropTypes from "prop-types";
import LoadingSpinner from "../../../LoadingSpinner";

const PaymentInfoDialog = ({
                               open,
                               onClose,
                               selectedQuote,
                               enrollmentObject,
                               enrollmentObjectIsError,
                               enrollmentObjectIsLoading
                           }) => {
    const { translate } = useCustomTranslation();

    const [error, setError] = useState(null);
    const [submitApplication] = useSubmitApplicationMutation();

    // Set paymentInfo to the hardcoded values
    const paymentInfo = {
        routingNumber: '031101279',
        accountNumber: '123456789445',
        accountType: 'checking',
    };

    const handleSubmitApplication = useCallback(async () => {
        if (!enrollmentObject) {
            setError(translate('No enrollment object available.'));
            return;
        }

        // Include ACH payment details from hardcoded values
        enrollmentObject.memberEnrollment.achBankRoutingNumber = paymentInfo.routingNumber;
        enrollmentObject.memberEnrollment.achBankAccountNumber = paymentInfo.accountNumber;
        enrollmentObject.memberEnrollment.achBankAccountType = paymentInfo.accountType;

        try {
            const response = await submitApplication(enrollmentObject).unwrap();
            console.log('Application submitted successfully:', response);
            onClose();  // Close dialog after successful submission
        } catch (error) {
            setError(translate('Error submitting application.'));
            console.error('Error submitting application:', error);
        }
    }, [enrollmentObject, paymentInfo, submitApplication, translate, onClose]);

    const handleFormSubmit = () => {
        setError(null);
        handleSubmitApplication();  // Submit the application
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{translate('Bank Account Information')}</DialogTitle>
                <DialogContent>

                    {enrollmentObjectIsLoading ? <Box  sx={{ overflow: 'hidden', width: '300px', height: '300px' }}><LoadingSpinner /></Box> : null }
                    {enrollmentObjectIsError ? <Alert severity="error">{translate('Failed to load the enrollment object.')}</Alert> : null }

                    { enrollmentObject && !enrollmentObjectIsError && !enrollmentObjectIsLoading ? (
                        <>
                        {error && (<Alert severity="error">{error}</Alert>)}
                        <Box sx={{ mb: 2 }}>
                            <strong>{translate('Routing Number')}:</strong> {paymentInfo.routingNumber}
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <strong>{translate('Account Number')}:</strong> {paymentInfo.accountNumber}
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <strong>{translate('Account Type')}:</strong> {translate('Checking')}
                        </Box>
                        </>
                        ) : null}
                </DialogContent>

            <DialogActions>
                <Button onClick={onClose} color="secondary">{translate('Cancel')}</Button>
                {!enrollmentObjectIsError && !enrollmentObjectIsLoading ? <Button onClick={handleFormSubmit} color="primary">{translate('Submit Enrollment')}</Button> : null}
            </DialogActions>
        </Dialog>
    );
};

PaymentInfoDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    selectedQuote: PropTypes.object.isRequired,
    enrollmentObject: PropTypes.object.isRequired,
    enrollmentObjectIsLoading: PropTypes.bool.isRequired,
    enrollmentObjectIsError: PropTypes.bool.isRequired,
};

export default PaymentInfoDialog;
