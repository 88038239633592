import React, { useState } from 'react';
import {
    Box,
    Typography,
    Alert,
    useTheme,
    Container,
    Paper
} from '@mui/material';
import RequestNewQuoteFormHSA from './RequestNewQuoteFormHSA';
import QuoteListHSA from './QuoteListHSA';
import useCustomTranslation from '../../../hooks/useCustomTranslation';
import {useGetEmployerIchraParentGroupQuery, useGetEmployerCalculatedEffectiveDateHSAQuery} from "../../../reducers/enrollmentApiSlice";
import useGetEmployerCode from "../../../hooks/useGetEmployerCode";
import AuthTokenService from "../../../services/AuthTokenService";
import LoadingSpinner from "../../LoadingSpinner";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedDependents} from "../../../reducers/hsaReducer";

const HSAEnrollment = () => {
    const [quoteData, setQuoteData] = useState(null);
    const [submissionStatus, setSubmissionStatus] = useState(null);

    const dispatch = useDispatch();
    const selectedDependents = useSelector((state) => state.hsa.selectedDependents);
    const selectedPlan = useSelector((state) => state.hsa.selectedPlanInfo);

    const theme = useTheme();
    const { translate } = useCustomTranslation();

    const { user } = AuthTokenService.getAuthInfo();
    const { employerCode, isEmployerCodeLoading, isError: isEmployerCodeError } = useGetEmployerCode({ userEmail: user });
    const { data: ichraParentGroupResponse, isError: ichraParentGroupError, isLoading: ichraParentGroupIsLoading, ichraParentGroupIsSuccess } = useGetEmployerIchraParentGroupQuery(employerCode, {
        skip: !employerCode // Don't execute the query until employerCode is available
    });
    const { data: calculatedHsaEffectiveDate, isError: calculatedHsaEffectiveDateError, isLoading: calculatedHsaEffectiveDateIsLoading, calculatedHsaEffectiveDateIsSuccess }
        = useGetEmployerCalculatedEffectiveDateHSAQuery({employerCode, email: user}, {
        skip: !employerCode || !user // Don't execute the query until the employerCode and the user email is available.
    });

    const handleQuoteRequestSuccess = (quoteResponse, dependents) => {
        setQuoteData(quoteResponse.quotE_RESULTS);
        dispatch(setSelectedDependents(dependents));  // Set the selected dependents in Redux
    };

    const isLoading = calculatedHsaEffectiveDateIsLoading || ichraParentGroupIsLoading;
    const hasError = calculatedHsaEffectiveDateError || ichraParentGroupError;

    const calculatedEffectiveDate = calculatedHsaEffectiveDate?.data?.calculatedHsaEffectiveDate ?? null;
    const parentCompanyId = ichraParentGroupResponse?.data?.parentCompanyId ?? null;
    const hasRequiredData = parentCompanyId != null && calculatedEffectiveDate != null;

    return (
        <Container maxWidth="lg">
            <Paper elevation={3} sx={{ p: 3, mt: 4, mb: 4 }}>
                <Typography variant="h4" gutterBottom>
                    {translate('HSA Enrollment')}
                </Typography>

                {
                    isLoading && (
                        <LoadingSpinner />
                    )
                }

                {
                    !isLoading && hasError && (
                        <Alert
                            severity="error"
                            sx={{
                                mb: theme.spacing(2),
                                color: theme.palette.error.main,
                                backgroundColor: theme.palette.error.light,
                            }}
                        >
                            {translate("Failed to load your employer's HSA info. Please contact them for assistance or try again later.")}
                        </Alert>
                    )
                }

                {!hasError && !isLoading && !quoteData && hasRequiredData && (
                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h6" sx={{ mb: theme.spacing(2) }}>
                            {translate('Fill in the following information to request a new quote')}
                        </Typography>
                        <RequestNewQuoteFormHSA
                            selectedEffectiveDate={calculatedEffectiveDate ?? ''}
                            selectedIchraParentGroupId={parentCompanyId ?? ''}
                            onSuccess={handleQuoteRequestSuccess}
                        />
                    </Box>
                )}

                {quoteData && (
                    <Box>
                        <Typography variant="h6" sx={{ mb: theme.spacing(2) }}>
                            {translate('Available Health Plans')}
                        </Typography>
                        <QuoteListHSA
                            quotes={quoteData}
                        />
                    </Box>
                )}
            </Paper>
        </Container>
    );
};

export default HSAEnrollment;
