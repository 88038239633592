// employerController.js
import { HttpMethod } from "../types/httpMethod";



export const employerEndpoints = (builder) => ({
    createEmployer: builder.mutation({
        query: (createEmployerRequest) => ({
            url: '/api/Employers',
            method: HttpMethod.Post,
            data: createEmployerRequest,
        }),
    }),
    createEmployerAdmin: builder.mutation({
        query: (createEmployerAdminRequest) => ({
            url: '/api/Employers/employer-admin',
            method: HttpMethod.Post,
            data: createEmployerAdminRequest,
        }),
    }),
    getEmployerByName: builder.query({
        query: ({ employerName, email }) => ({
            url: `/api/Employers/ByName/${encodeURIComponent(employerName)}?email=${encodeURIComponent(email)}`,
            method: HttpMethod.Get,
        }),
    }),
    getEmployerWithEmployeesByName: builder.query({
        query: (employerName) => ({
            url: `/api/Employers/byname/${encodeURIComponent(employerName)}/employees`,
            method: HttpMethod.Get,
        }),
    }),
    getEmployerByEIN: builder.query({
        query: ({ employerEIN, email }) => ({
            url: `/api/Employers/ByEIN/${encodeURIComponent(employerEIN)}?email=${encodeURIComponent(email)}`,
            method: HttpMethod.Get,
        }),
    }),
    getEmployerWithEmployeesByEIN: builder.query({
        query: (employerEIN) => ({
            url: `/api/Employers/byein/${encodeURIComponent(employerEIN)}/employees`,
            method: HttpMethod.Get,
        }),
    }),
    getEmployerByCode: builder.query({
        query: ({ employerCode, email }) => ({
            url: `/api/Employers/ByCode/${encodeURIComponent(employerCode)}?email=${encodeURIComponent(email)}`,
            method: HttpMethod.Get,
        }),
    }),
    getEmployerWithEmployeesByCompanyId: builder.query({
        query: (companyId) => ({
            url: `/api/Employers/bycompanyid/${encodeURIComponent(companyId)}/employees`,
            method: HttpMethod.Get,
        }),
    }),
    getEmployerByCompanyId: builder.query({
        query: ({ companyId, email }) => ({
            url: `/api/Employers/ByCompanyId/${encodeURIComponent(companyId)}?email=${encodeURIComponent(email)}`,
            method: HttpMethod.Get,
        }),
    }),
    getAllEmployers: builder.query({
        query: () => ({
            url: '/api/Employers',
            method: HttpMethod.Get,
        }),
    }),
    getEmployerWithEmployeesByEmployerId: builder.query({
        query: (employerId) => ({
            url: `/api/Employers/byemployerid/${encodeURIComponent(employerId)}/employees`,
            method: HttpMethod.Get,
        }),
    }),
    getAddressesByEmployerCode: builder.query({
        query: ({ employerCode, email }) => ({
            url: `/api/Employers/addressbyemployercode/${encodeURIComponent(employerCode)}?email=${encodeURIComponent(email)}`,
            method: HttpMethod.Get,
        }),
    }),
    addOrUpdateEmployerAddress: builder.mutation({
        query: (addressDto) => ({
            url: '/api/Employers/updateemployeraddress',
            method: HttpMethod.Post,
            data: addressDto,
        }),
    }),
    upsertEmployerLogo: builder.mutation({
        query: (logoDto) => ({
            url: '/api/Employers/UpsertEmployerLogo',
            method: HttpMethod.Post,
            data: logoDto,
        }),
    }),
    getEmployerLogoByEmployerCode: builder.query({
        query: (employerCode) => ({
            url: `/api/Employers/GetEmployerLogoByEmployerCode/${encodeURIComponent(employerCode)}`,
            method: HttpMethod.Get,
        }),
    }),

    getEmployeesByEmployerCode: builder.query({
        query: (employerCode) => ({
            url: `/api/Employers/${encodeURIComponent(employerCode)}/employees`,
            method: HttpMethod.Get,
        }),
    }),
    getEmployerAdminsByCode: builder.query({
        query: (employerCode) => ({
            url: `/api/Employers/employer-admins/${encodeURIComponent(employerCode)}`,
            method: HttpMethod.Get,
        }),
    }),
    removeEmployerAdmin: builder.mutation({
        query: (removeEmployerAdminRequest) => ({
            url: '/api/Employers/remove-employer-admin',
            method: HttpMethod.Post,
            data: removeEmployerAdminRequest,
        }),
    }),
    updateEmployer: builder.mutation({
        query: (updateEmployerRequest) => ({
            url: '/api/Employers/update-employer',
            method: HttpMethod.Put,
            data: updateEmployerRequest,
        }),
    }),
    upsertBenefitRuleAndPayPeriod: builder.mutation({
        query: (upsertBenefitRuleAndPayPeriodRequest) => ({
            url: '/api/Employers/upsert-benefit-rule-and-pay-period',
            method: HttpMethod.Post,
            data: upsertBenefitRuleAndPayPeriodRequest,
        }),
    }),
    getEmployerIchraParentGroup: builder.query({
        query: (employerCode) => ({
            url: `/api/Employers/get-employer-ichra-parent-group?employerCode=${encodeURIComponent(employerCode)}`,
            method: HttpMethod.Get,
        }),
        transformResponse: (response) => response ?? null,
    }),
    upsertIchraParentGroup: builder.mutation({
        query: (upsertIchraParentGroupRequest) => ({
            url: '/api/Employers/upsert-ichra-parent-groups',
            method: HttpMethod.Post,
            data: upsertIchraParentGroupRequest,
        }),
    }),
    updateIsIchraCompany: builder.mutation({
        query: (updateIsIchraCompanyRequest) => ({
            url: '/api/Employers/update-is-ichra-company',
            method: HttpMethod.Post,
            data: updateIsIchraCompanyRequest,
        }),
    }),
    getEmployerCalculatedEffectiveDate: builder.query({
        query: ({employerCode, email}) => ({
            url: `/api/Employers/get-effective-date-for-employee-from-employer?employerCode=${encodeURIComponent(employerCode)}&email=${encodeURIComponent(email)}`,
            method: HttpMethod.Get,
        }),
        transformResponse: (response) => response ?? null,
    }),
});
