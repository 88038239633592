// extendedApi.js

import baseApi from './baseApi';
import { uiSupportEndpoints } from '../../controllers/uiSupportController';
import { accountEndpoints } from '../../controllers/accountController';
import { carrierEndpoints } from '../../controllers/carrierController';
import { benefitEnrollmentEndpoints } from '../../controllers/benefitEnrollmentController';
import { dependentEndpoints } from '../../controllers/dependentController';
import { employerPlanEndpoints } from '../../controllers/employerPlanController';
import { cognitoEndpoints } from '../../controllers/cognitoController';
import { addressEndpoints } from "../../controllers/addressController";
import { internalRatesEndpoints } from "../../controllers/internalRatesController";
import { planSearchEndpoints } from '../../controllers/planSearchController';
import { employerEndpoints } from '../../controllers/employerController';
import { planEndpoints } from "../../controllers/planController";
import { employerOpenEnrollmentEndpoints } from "../../controllers/employerOpenEnrollmentController";
import {insuranceQuestionsEndpoints } from "../../controllers/insuranceQuestionsController";
import {employerAdminRatesEndpoints} from "../../controllers/employerAdminRatesController";
import {cognitoGroupEndpoints} from "../../controllers/cognitoGroupController";
import {employmentDetailsEndpoints} from "../../controllers/employmentDetailsController";
import {hsaEndpoints} from "../../controllers/hsaController";
import {ebToolKitEndpoints} from "../../controllers/ebToolKitController";
import {enrollmentPdfEndpoints} from "../../controllers/enrollmentPDFController";
import {payPeriodAndBenefitEffectiveRulesEndpoints} from "../../controllers/payPeriodAndBenefitEffectiveRulesController";

const extendedApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        ...uiSupportEndpoints(builder),
        ...accountEndpoints(builder),
        ...carrierEndpoints(builder),
        ...benefitEnrollmentEndpoints(builder),
        ...dependentEndpoints(builder),
        ...employerPlanEndpoints(builder),
        ...cognitoEndpoints(builder),
        ...addressEndpoints(builder),
        ...internalRatesEndpoints(builder),
        ...planSearchEndpoints(builder),
        ...employerEndpoints(builder),
        ...planEndpoints(builder),
        ...employerOpenEnrollmentEndpoints(builder),
        ...insuranceQuestionsEndpoints(builder),
        ...employerAdminRatesEndpoints(builder),
        ...cognitoGroupEndpoints(builder),
        ...employmentDetailsEndpoints(builder),
        ...hsaEndpoints(builder),
        ...ebToolKitEndpoints(builder),
        ...enrollmentPdfEndpoints(builder),
        ...payPeriodAndBenefitEffectiveRulesEndpoints(builder)
    }),
    overrideExisting: false,
});

export default extendedApi;
