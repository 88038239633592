import React, { useEffect } from 'react';
import { TextField, Box, CircularProgress } from '@mui/material';
import { useGetPersonalAndEmploymentDetailsQuery, useGetAddressesQuery, useGetLocationsQuery } from '../../../reducers/enrollmentApiSlice';
import useCustomTranslation from '../../../hooks/useCustomTranslation';
import authTokenService from '../../../services/AuthTokenService';

const EbZipCodeInput = ({ onZipCodeChange, zipCode, onLocationChange }) => {
    const { translate } = useCustomTranslation();
    const authInfo = authTokenService.getAuthInfo();
    const userEmail = authInfo.user;

    const { data: personalDetails, isLoading: isPersonalDetailsLoading } = useGetPersonalAndEmploymentDetailsQuery({ email: userEmail });
    const { data: addressesData, isLoading: isAddressesLoading } = useGetAddressesQuery({ email: userEmail });

    const { data: locationsData, isLoading: isLocationsLoading } = useGetLocationsQuery(zipCode, {
        skip: !zipCode,
    });

    useEffect(() => {
        if (personalDetails && personalDetails.zipCode) {
            onZipCodeChange(personalDetails.zipCode);
            console.log(`ZIP Code retrieved from personal details: ${personalDetails.zipCode}`);
        } else if (addressesData && addressesData.length > 0) {
            const postalCode = addressesData[0].postalCode.slice(0, 5);
            onZipCodeChange(postalCode);
            console.log(`ZIP Code retrieved from addresses: ${postalCode}`);
        }
    }, [personalDetails, addressesData, onZipCodeChange]);

    // Pass location back up to parent component when it changes
    useEffect(() => {
        if (locationsData && locationsData.locationList && locationsData.locationList.length > 0) {
            const firstLocation = locationsData.locationList[0];
            onLocationChange(firstLocation);  // Pass the first location to the parent component
            console.log('Setting first location in EbZipCodeInput:', firstLocation);
        }
    }, [locationsData, onLocationChange]);

    const handleChange = (event) => {
        onZipCodeChange(event.target.value);
    };

    if (isPersonalDetailsLoading || isAddressesLoading || isLocationsLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 56 }}>
                <CircularProgress size={24} />
            </Box>
        );
    }

    return (
        <Box sx={{ minWidth: 120, mb: 2 }}>
            <TextField
                fullWidth
                label={translate('ZIP Code')}
                variant="outlined"
                value={zipCode}
                onChange={handleChange}
                inputProps={{ maxLength: 5 }}
            />
            {locationsData && locationsData.locationList && (
                <Box sx={{ mt: 2 }}>
                    <h4>{translate('Locations')}</h4>
                    <ul>
                        {locationsData.locationList.map((location) => (
                            <li key={location.internalId}>
                                {location.city}, {location.stateName} ({location.zipCode})
                            </li>
                        ))}
                    </ul>
                </Box>
            )}
        </Box>
    );
};

export default EbZipCodeInput;
