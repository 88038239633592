import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Paper, TextField, Grid, Link } from '@mui/material';
import useCustomTranslation from "../../hooks/useCustomTranslation";
import DependentCoverage from './DependentCoverage';
import CoverageAmountBox from '../LifeInsurance/CoverageAmountBox';  // Component for coverage amount selection
import { updateWaiverReason } from '../../reducers/benefitEnrollmentSlice';
import { updateSelectedPlansWithDependents } from '../../reducers/planCoverageSlice';
import { useGetEmployerAdminRatesByPlanIdQuery } from '../../reducers/enrollmentApiSlice';
import useGetEmployerCode from '../../hooks/useGetEmployerCode';

const SelectedPlans = ({ selectedPlans, dependentsData, onNavigateToPlanSelectionPage }) => {
    const { translate } = useCustomTranslation();
    const dispatch = useDispatch();
    const waiverReasons = useSelector(state => state.benefitEnrollment.waiverReasons);
    const [dependentsCoverage, setDependentsCoverage] = useState({});
    const [waivedPlans, setWaivedPlans] = useState({});
    const [coverageAmounts, setCoverageAmounts] = useState({});
    const { user: userEmail } = useGetEmployerCode({ shouldRedirect: false });
    const { employerCode } = useGetEmployerCode({ userEmail, shouldRedirect: false });

    // Initialize waived plans and coverage amounts based on the selected plans
    useEffect(() => {
        const initialWaivedPlans = {};
        const initialCoverageAmounts = {};

        selectedPlans.forEach(plan => {
            initialWaivedPlans[plan.planId] = plan.decision === 'waive';
            initialCoverageAmounts[plan.planId] = plan.coverageAmount || 0;
        });

        setWaivedPlans(initialWaivedPlans);
        setCoverageAmounts(initialCoverageAmounts);
        console.log("Initial waived plans:", initialWaivedPlans);
        console.log("Initial coverage amounts:", initialCoverageAmounts);
    }, [selectedPlans]);

    // Handle waiver reason changes
    const handleWaiverReasonChange = useCallback((planId, reason) => {
        dispatch(updateWaiverReason({ planId, reason }));
        console.log(`Updated waiver reason for plan ${planId}:`, reason);
    }, [dispatch]);

    // Handle dependent coverage change - updating full dependent info instead of just dependentId
    const handleDependentCoverageChange = useCallback((planId, dependent, isCovered) => {
        setDependentsCoverage(prevState => ({
            ...prevState,
            [planId]: {
                ...prevState[planId],
                [dependent.dependentId]: {
                    ...dependent,
                    isCovered
                }
            }
        }));
        console.log(`Updated dependent coverage for plan ${planId}:`, {
            dependentId: dependent.dependentId,
            fullDependentInfo: dependent,
            isCovered
        });
    }, []);

    // Handle coverage amount change
    const handleCoverageAmountChange = useCallback((planId, newValue) => {
        setCoverageAmounts(prevState => ({
            ...prevState,
            [planId]: newValue
        }));
        console.log(`Updated coverage amount for plan ${planId}:`, newValue);
    }, []);

    // Whenever selectedPlans, dependentsCoverage, waivedPlans, waiverReasons, or coverageAmounts change, update the store
    useEffect(() => {
        const updatedPlans = selectedPlans.map(plan => ({
            ...plan,
            coveredDependents: dependentsCoverage[plan.planId] || {}, // Add full dependent info here
            coverageAmount: coverageAmounts[plan.planId] || 0,       // Add selected coverage amount
            isWaived: waivedPlans[plan.planId] || false,             // Track if the plan is waived
            waiverReason: waiverReasons[plan.planId] || ''           // Add waiver reason if applicable
        }));

        // Dispatch the updated plans to the redux store
        dispatch(updateSelectedPlansWithDependents(updatedPlans));

        console.log('Updating selected plans with full dependent info:', {
            updatedPlans,
            dependentsCoverage,
            coverageAmounts,
            waivedPlans,
            waiverReasons
        });
    }, [selectedPlans, dependentsCoverage, waivedPlans, waiverReasons, coverageAmounts, dispatch]);

    const renderField = useCallback((label, value) => {
        if (value === undefined || value === null || value === '') {
            return null;
        }
        return (
            <Grid item xs={12}>
                <Typography variant="subtitle2" component="dt">{translate(label)}:</Typography>
                <Typography variant="body2" component="dd">{value}</Typography>
            </Grid>
        );
    }, [translate]);

    return (
        <Box component="section" aria-labelledby="selected-plans-title">
            <Typography id="selected-plans-title" component="h2" variant="h6" gutterBottom>
                {translate('Selected Plans')}
            </Typography>
            {selectedPlans.length > 0 ? (
                selectedPlans.map(plan => {
                    const isWaived = waivedPlans[plan.planId];
                    const { data: rateData, isLoading: isRateLoading } = useGetEmployerAdminRatesByPlanIdQuery({
                        planId: plan.planId,
                        email: userEmail,
                        employerCode: employerCode
                    });

                    const rateInfo = rateData && rateData.length > 0 ? rateData[0] : null;

                    return (
                        <Paper key={plan.planId} elevation={3} sx={{ p: 3, mt: 3, mb: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                {plan.productName}
                                {!isWaived && ` (${plan.benefitTypeName})`}
                            </Typography>
                            {isWaived ? (
                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="body1" sx={{ color: 'error.main' }} gutterBottom>
                                        {translate('Plan Waived')}
                                    </Typography>
                                    <TextField
                                        label={translate('Waiver Reason')}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        value={waiverReasons[plan.planId] || ''}
                                        onChange={(e) => handleWaiverReasonChange(plan.planId, e.target.value)}
                                    />
                                </Box>
                            ) : (
                                <>
                                    <Grid container spacing={2} component="dl">
                                        {renderField("Description", plan.description)}
                                        {renderField("Carrier", plan.carrierName)}
                                    </Grid>
                                    {!isRateLoading && rateInfo && plan.benefitTypeName === 'Life' && (
                                        <CoverageAmountBox
                                            min={0}  // Set min to always be 0
                                            max={rateInfo.max}
                                            step={rateInfo.increments}
                                            value={coverageAmounts[plan.planId] || rateInfo.eoiMax}
                                            onChange={(newValue) => handleCoverageAmountChange(plan.planId, newValue)}
                                        />
                                    )}
                                    {dependentsData.length > 0 && (
                                        <DependentCoverage
                                            planId={plan.planId}
                                            dependentsData={dependentsData}
                                            dependentsCoverage={dependentsCoverage[plan.planId] || {}}
                                            onDependentChange={handleDependentCoverageChange}
                                        />
                                    )}
                                </>
                            )}
                        </Paper>
                    );
                })
            ) : (
                <Paper elevation={2} sx={{ p: 3, mt: 2, backgroundColor: '#f5f5f5' }}>
                    <Typography variant="body1" align="center">
                        {translate('No plans selected')}
                    </Typography>
                </Paper>
            )}
            <Box sx={{ mt: 2, textAlign: 'right' }}>
                <Link
                    component="button"
                    variant="body2"
                    onClick={onNavigateToPlanSelectionPage}
                    sx={{ textDecoration: 'none' }}
                    aria-label={translate('Edit Selected Plans')}
                >
                    {translate('Edit Selected Plans')}
                </Link>
            </Box>
        </Box>
    );
};

export default SelectedPlans;
