import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, TextField } from '@mui/material';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import useCustomTranslation from "../../hooks/useCustomTranslation";

const CoverageAmountBox = React.memo(({ min, max, step, value, onChange }) => {
    const { translate } = useCustomTranslation();

    // Set initial value to the min value (0 in this case)
    const [localValue, setLocalValue] = useState(min);

    useEffect(() => {
        // Sync with external value but only set to min initially
        if (value !== localValue) {
            setLocalValue(value);
        }
    }, [value, localValue]);

    const handleIncrement = () => {
        const newValue = Math.min(localValue + step, max); // Ensure value doesn't exceed max
        setLocalValue(newValue);
        onChange(newValue); // Notify parent on change
    };

    const handleDecrement = () => {
        const newValue = Math.max(localValue - step, min); // Ensure value doesn't go below min
        setLocalValue(newValue);
        onChange(newValue); // Notify parent on change
    };

    return (
        <Box sx={{ width: '100%', mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography id="coverage-amount-box" gutterBottom>
                {translate('Coverage Amount')}
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <IconButton onClick={handleDecrement} disabled={localValue <= min}>
                    <ArrowDownward />
                </IconButton>

                <TextField
                    value={localValue.toLocaleString()}
                    inputProps={{ readOnly: true, style: { textAlign: 'center' } }}
                    variant="outlined"
                    size="small"
                />

                <IconButton onClick={handleIncrement} disabled={localValue >= max}>
                    <ArrowUpward />
                </IconButton>
            </Box>

            <Typography variant="body2" color="text.secondary" align="right" sx={{ mt: 1 }}>
                {translate('Selected Amount')}: ${localValue.toLocaleString()}
            </Typography>
        </Box>
    );
});

export default CoverageAmountBox;
